import React from "react";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { ImInstagram } from "react-icons/im";
import { Content, Background } from "./styles";
import { Container } from "react-bootstrap";
// import LgpdNotification from "~/shared/LgpdNotification";

const Footer = () => {
    return (
        <Background>
            <div className="border" />

            <Container>
                <Content>
                    <div className="info-contatct">
                        <img src="/images/logo-fotop.png" alt="Logo fotop" />

                        <div className="social-media">
                            <p>Siga o Fotop</p>
                            <div>
                                <a target="_blank" href="https://www.facebook.com/fotopbroficial">
                                    <FaFacebookF size={20} color="#000" />
                                </a>
                                <a target="_blank" href="https://www.instagram.com/fotopbroficial/">
                                    <ImInstagram size={20} color="#000" />
                                </a>
                                <a target="_blank" href="https://www.linkedin.com/company/fotop/">
                                    <FaLinkedinIn size={20} color="#000" />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="useful-links">
                        <div>
                            <strong>Institucional</strong>
                            <a target="_blank" href="https://fotop.io/quemsomos/">
                                O que é a Fotop?
                            </a>
                            <a target="_blank" href="http://blog.fotop.io/">
                                Blog
                            </a>
                            <a target="_blank" href="https://fotop.com.br/fotos/commerceft/pacotes/mostrar">
                                Pacotes e Produtos
                            </a>
                        </div>

                        <div>
                            <strong>Conta</strong>
                            <a target="_blank" href="https://fotop.com.br/fotos/cliente/cadastro/editar">
                                Minha conta
                            </a>
                            <a target="_blank" href="https://fotop.com.br/fotos/meuspedidos">
                                Meus pedidos
                            </a>
                            <a target="_blank" href="https://fotop.com.br/fotos/login">
                                Login
                            </a>
                            <a target="_blank" href="https://fotop.com.br/fotos/meuspedidos">
                                Baixar fotos
                            </a>
                            <a target="_blank" href="https://fotop.com.br/fotos/login/cadastro">
                                Cadastre-se
                            </a>
                        </div>
                        <div>
                            <strong>Ajuda</strong>
                            <a target="_blank" href="https://fotop.com/fotos/r/tBi93P">
                                Como comprar
                            </a>
                            <a target="_blank" href="https://fotop.com/fotos/r/WPSWv0">
                                Contate-nos
                            </a>
                            <a target="_blank" href="https://fotop.com/fotos/r/XqzZDS">
                                Central de ajuda
                            </a>
                        </div>
                        <div>
                            <strong>Fotop Serviços</strong>
                            <a target="_blank" href="https://servicos.fotop.com.br/">
                                Contratar um fotógrafo
                            </a>
                            <a target="_blank" href="https://fotop.io/">
                                Sou fotógrafo
                            </a>
                        </div>
                        <div>
                            <strong>Venda suas fotos</strong>
                            <a target="_blank" href="https://fotop.io/">
                                Fotógrafos
                            </a>
                            <a target="_blank" href="https://fotop.io/organizador">
                                Organizadores
                            </a>
                            <a target="_blank" href="https://fotop.io/">
                                Escolas
                            </a>
                            <a target="_blank" href="https://fotop.io/">
                                Outros
                            </a>
                        </div>
                    </div>

                    <div className="rodape">
                        <p>
                            O FOTOP não divulga, transfere ou, de qualquer forma, fornece seus dados para terceiros.{" "}
                            <a href="https://www.fotop.com.br/fotos/index/termos-privacidade/">
                                Para nós, sua privacidade é fundamental.
                            </a>
                            <br />
                            ©2021 Fotop | 11 3868-2333 | sac@fotop.com.br | CNPJ 07.936.428/0001-90 | Rua Ricardo
                            Severo, 73 - São Paulo - SP
                        </p>
                    </div>
                </Content>
            </Container>

            {/*<LgpdNotification />*/}
        </Background>
    );
};

export default Footer;
